/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnyAction, AsyncThunk, AsyncThunkAction } from '@reduxjs/toolkit';
import { AppState } from '@store/appStore.ts';
import { InterfaceThunkDeleteData } from '@store/reducers/reducerHelpers/thunkDelete.ts';
import { InterfaceThunkGetData } from '@store/reducers/reducerHelpers/thunkGet.ts';
import { InterfaceThunkPostData } from '@store/reducers/reducerHelpers/thunkPost.ts';
import { ThunkDispatch } from 'redux-thunk';

import { DispatchActionType } from '@/types/DispatchActionType.ts';

export interface InterfaceVarReplace {
  [key: string]: string;
}

export interface InterfaceFunctionReplace {
  [key: string]: (state: AppState) => string;
}

export const defaultTypeDebounceTimeMs = 500; // 0.5 seconds

export function replaceUrlVariable(url: string, urlVarsReplaceMap: InterfaceVarReplace): string {
  let fullUrl: string = url;
  if (urlVarsReplaceMap !== undefined && Object.keys(urlVarsReplaceMap).length > 0) {
    Object.keys(urlVarsReplaceMap).forEach((key) => {
      fullUrl = fullUrl.replace(key, urlVarsReplaceMap[key]);
    });
  }
  return fullUrl ?? '';
}

export function replaceUrlState(
  url: string,
  replaceMap: InterfaceFunctionReplace,
  getState: () => AppState | unknown,
): string {
  let fullUrl: string = url;
  if (Object.keys(replaceMap).length > 0) {
    const state = getState() as AppState;
    Object.keys(replaceMap).forEach((key) => {
      fullUrl = fullUrl.replace(key, replaceMap[key](state));
    });
  }
  return fullUrl ?? '';
}

// New utility function
export function safeDispatchActions(
  dispatch: ThunkDispatch<any, unknown, AnyAction>,
  actions: DispatchActionType[],
): void {
  actions.forEach((action) => {
    // @ts-expect-error - Dispatch will handle both types correctly at runtime
    dispatch(action);
  });
}

export type DispatchAction =
  | { payload: any; type: string }
  | AsyncThunk<any, InterfaceThunkGetData | InterfaceThunkDeleteData | InterfaceThunkPostData | undefined | any, object>
  | AsyncThunkAction<any, any, any>
  | ((...args: any[]) => any);
