// src/schemas/deliverySchemas.ts
import { z } from 'zod';

import {
  deliveryNextStatusOptionsSchema,
  interfaceVehicleMapSchema,
  locationSchema,
  noteSchema,
  scheduleWindowSchema,
} from './commonSchemas'; // Adjust path as needed

// Schema for the structure within deliverySteps array from the example JSON
const deliveryStepSimpleWithTasksSchema = z.object({
  deliveryStepUuid: z.string().uuid(),
  deliveryUuid: z.string().uuid(),
  // stepOrder: z.number().int(), // <-- REMOVE THIS LINE
  stepOrderNo: z.number().int(), // <-- ADD THIS LINE (Matches API: stepOrderNo)
  nextDeliveryStepUuid: z.string().uuid().nullable(),
  locationRouteUuid: z.string().uuid().nullable(),
  // Define schema for the task object within deliveryStepTasks based on error's receivedValue
  deliveryStepTasks: z.array(
    z
      .object({
        uuid: z.string().uuid(),
        deliveryStepTaskUuid: z.string().uuid(), // Seems redundant, but present
        deliveryStepUuid: z.string().uuid(),
        deliveryStepTaskTypeSlug: z.string(),
        // Add other potential fields from the full task object if known, mark as optional
        // e.g., createdAt: z.string().datetime().optional(),
        // completedAt: z.string().datetime().nullable().optional(),
        // taskOrderNo: z.number().int().optional(),
        // parcelUuids: z.array(z.string().uuid()).optional(),
        // deliveryStepTaskRequiredProofs: z.array(z.unknown()).optional(), // Define proof schema later if needed
        // deliveryStepTaskContactUuids: z.array(z.string().uuid()).optional(),
        // deliveryStepTaskDocumentUuids: z.array(z.string().uuid()).optional(),
      })
      .passthrough(), // Use passthrough to allow extra fields without failing validation initially
  ),
});

// Main Delivery Schema based on InterfaceDelivery and example JSON
export const deliverySchema = z.object({
  uuid: z.string().uuid(),
  createdAt: z.string().datetime({ message: 'Invalid datetime string for createdAt' }),
  quoteStatusChangedAt: z.string().datetime({ message: 'Invalid datetime string for quoteStatusChangedAt' }),
  liveStatusChangedAt: z.string().datetime({ message: 'Invalid datetime string for liveStatusChangedAt' }),
  startedAt: z.string().datetime({ message: 'Invalid datetime string for startedAt' }).nullable(),
  completedAt: z.string().datetime({ message: 'Invalid datetime string for completedAt' }).nullable(),
  name: z.string(),
  description: z.string(),
  isActive: z.boolean(),
  activeDeliveryStepUuid: z.string().uuid().nullable(), // Already nullable
  activeDeliveryStepTaskUuid: z.string().uuid().nullable(), // Already nullable
  originatorSlug: z.string(),
  originatorAccountUuid: z.string().uuid(),
  deliveryQuoteStatusTypeSlug: z.string(),
  deliveryLiveStatusTypeSlug: z.string(),
  deliveryCancelTypeSlug: z.string(),
  isScheduled: z.boolean(),
  distanceM: z.number(),
  originalDistanceM: z.number(),
  durationS: z.number(),
  originalDurationS: z.number(),
  maxVolumeCm3: z.number(),
  maxWeightKg: z.number(),
  totalSteps: z.number().int(),
  currencyCode: z.string().length(3),
  // maxParcelPrice: z.string(), // <-- REMOVE THIS LINE
  maxParcelPriceMinor: z.string(), // <-- ADD THIS LINE (Matches API: maxParcelPriceMinor)
  deliveryQuoteUuids: z.array(z.string().uuid()),
  deliveryDocuments: z.array(z.string().uuid()), // Assuming UUIDs
  deliveryScheduleWindows: z.array(scheduleWindowSchema),
  deliverySteps: z.array(deliveryStepSimpleWithTasksSchema),
  vehicleMap: interfaceVehicleMapSchema,
  location: locationSchema, // Use the nullable schema from commonSchemas
  nextStatusOptions: deliveryNextStatusOptionsSchema,
  notes: z.array(noteSchema),
});

// Schema for the API response containing a map/object of deliveries under a 'data' key, keyed by UUID
export const deliveryListResponseSchema = z.object({
  data: z.record(z.string().uuid(), deliverySchema),
  // Add other potential top-level keys from the API response if they exist (e.g., links, meta for pagination)
  // links: z.any().optional(),
  // meta: z.any().optional(),
});

// Schema for API responses returning a map/object of deliveries keyed by UUID (if used elsewhere)
export const deliveryMapSchema = z.record(z.string().uuid(), deliverySchema);
