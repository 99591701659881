import { CalendarEvent } from '@interfaces/InterfaceCalendarEvent.ts';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { meLogOut } from '@store/reducers/meReducer.ts';

export interface CalendarState {
  events: CalendarEvent[];
}

// Get current date information
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth();
const currentDay = currentDate.getDate();

// Create a date for the 15th of current month (or use another day if you prefer)
const focusDay = 15;

const initialState: CalendarState = {
  events: [
    // Today's event
    {
      id: '1',
      title: 'Current Delivery',
      start: new Date(currentYear, currentMonth, currentDay, 10, 0).toISOString(),
      end: new Date(currentYear, currentMonth, currentDay, 12, 0).toISOString(),
      allDay: false,
    },
    // Multiple events on the focus day
    {
      id: '2',
      title: 'Morning Delivery',
      start: new Date(currentYear, currentMonth, focusDay, 8, 0).toISOString(),
      end: new Date(currentYear, currentMonth, focusDay, 9, 30).toISOString(),
      allDay: false,
    },
    {
      id: '3',
      title: 'Delivery to Downtown',
      start: new Date(currentYear, currentMonth, focusDay, 11, 0).toISOString(),
      end: new Date(currentYear, currentMonth, focusDay, 12, 30).toISOString(),
      allDay: false,
    },
    {
      id: '4',
      title: 'Express Delivery',
      start: new Date(currentYear, currentMonth, focusDay, 14, 0).toISOString(),
      end: new Date(currentYear, currentMonth, focusDay, 15, 0).toISOString(),
      allDay: false,
    },
    {
      id: '5',
      title: 'Late Pickup',
      start: new Date(currentYear, currentMonth, focusDay, 16, 30).toISOString(),
      end: new Date(currentYear, currentMonth, focusDay, 17, 30).toISOString(),
      allDay: false,
    },
    // A few other days in the month
    {
      id: '6',
      title: 'Scheduled Maintenance',
      start: new Date(currentYear, currentMonth, 20, 9, 0).toISOString(),
      end: new Date(currentYear, currentMonth, 20, 12, 0).toISOString(),
      allDay: false,
    },
    {
      id: '7',
      title: 'Special Delivery',
      start: new Date(currentYear, currentMonth, 25, 13, 0).toISOString(),
      end: new Date(currentYear, currentMonth, 25, 16, 0).toISOString(),
      allDay: false,
    },
  ],
};

export const sliceName = 'calendar';

export const calendarSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    addEvent: (state, action: PayloadAction<CalendarEvent>) => {
      state.events.push(action.payload);
    },
    updateEvent: (state, action: PayloadAction<CalendarEvent>) => {
      const index = state.events.findIndex((event) => event.id === action.payload.id);
      if (index !== -1) {
        state.events[index] = action.payload;
      }
    },
    removeEvent: (state, action: PayloadAction<string>) => {
      const index = state.events.findIndex((event) => event.id === action.payload);
      if (index !== -1) {
        state.events.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(meLogOut.fulfilled, () => initialState);
  },
});

export const { addEvent, updateEvent, removeEvent } = calendarSlice.actions;

export default calendarSlice.reducer;
