import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '@store/appStore.ts';
import { ZodError } from 'zod'; // Import ZodError

// Helper to get nested value, returns undefined if path is invalid
const getReceivedValue = (data: unknown, path: (string | number)[]): unknown => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let current: any = data; // Use 'any' here as structure changes during iteration
  try {
    for (const p of path) {
      if (current === null || typeof current !== 'object') return undefined;
      current = current[p];
    }
    // Limit the size of the stored value (e.g., stringify and truncate)
    if (typeof current === 'object' && current !== null) {
      try {
        const stringified = JSON.stringify(current);
        return stringified.length > 200 ? stringified.substring(0, 197) + '...' : current;
      } catch {
        return '[Unserializable Object]';
      }
    }
    if (typeof current === 'string' && current.length > 200) {
      return current.substring(0, 197) + '...';
    }
    return current;
  } catch {
    return undefined; // Path might be invalid
  }
};
// EXPORT this interface
export interface UniqueValidationError {
  path: (string | number)[]; // Zod path can include numbers for array indices
  message: string;
  receivedValue: unknown; // Store the actual value received (or snippet)
}

// Define the error structure type
export interface ValidationErrorEntry {
  timestamp: string;
  count: number;
  uniqueErrors: {
    [errorKey: string]: UniqueValidationError;
  };
}

// Updated to match the actual structure of state.devValidation.errors
export type ValidationErrorsState = {
  [url: string]: ValidationErrorEntry;
};

// Payload now includes the raw ZodError and the data that was validated
interface ValidationErrorPayload {
  url: string;
  error: ZodError;
  receivedData: unknown;
  timestamp: string;
}

// Export this interface so it can be used in AppState type
export interface DevValidationState {
  errors: ValidationErrorsState;
}

const initialState: DevValidationState = {
  errors: {},
};

export const sliceName = 'devValidation';

const devValidationSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    addValidationError: (state, action: PayloadAction<ValidationErrorPayload>) => {
      const { url, error, receivedData, timestamp } = action.payload;
      const existingEntry = state.errors[url];
      // Copy existing unique errors or start fresh for this URL
      const uniqueErrorsMap = existingEntry ? { ...existingEntry.uniqueErrors } : {};

      error.issues.forEach((issue) => {
        const errorKey = issue.path.join('.') + '::' + issue.message;
        // Only add if this specific error type/path hasn't been seen for this URL yet
        if (!uniqueErrorsMap[errorKey]) {
          uniqueErrorsMap[errorKey] = {
            path: issue.path,
            message: issue.message,
            receivedValue: getReceivedValue(receivedData, issue.path),
          };
        }
      });

      state.errors[url] = {
        timestamp, // Update timestamp to the latest failure
        count: existingEntry ? existingEntry.count + 1 : 1, // Increment total failure count
        uniqueErrors: uniqueErrorsMap, // Store the map of unique errors
      };
    },
    clearValidationErrors: (state) => {
      state.errors = {};
    },
    clearValidationError: (state, action: PayloadAction<string>) => {
      const url = action.payload;
      delete state.errors[url];
    },
  },
});

export const { addValidationError, clearValidationErrors, clearValidationError } = devValidationSlice.actions;

// Fix the selector to properly handle the state
export const selectDevValidationErrors = (state: AppState): ValidationErrorsState => {
  return state.devValidation.errors || {};
};

export default devValidationSlice.reducer;
