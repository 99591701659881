import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBox, faPlus, faTruck, faUserPlus } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

interface MenuItem {
  id: string;
  icon: IconDefinition;
  label: string;
  path: string;
  colorClass: string;
}

const EasyModePlusNav: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const menuItems: MenuItem[] = [
    { id: 'add-parcel', icon: faBox, label: 'Add Parcel', path: '/DeliveryAddEasy', colorClass: 'bg-primary' },
    { id: 'add-vehicle', icon: faTruck, label: 'Add Vehicle', path: '/VehicleAdd', colorClass: 'bg-secondary' },
    { id: 'add-driver', icon: faUserPlus, label: 'Add Driver', path: '/DriverAddCompany', colorClass: 'bg-success' },
  ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const calculatePosition = (index: number, totalItems: number) => {
    const angleIncrement = 90 / (totalItems - 1);
    const angle = 180 - index * angleIncrement;
    const radius = 80;
    const x = radius * Math.cos((angle * Math.PI) / 180);
    const y = radius * Math.sin((angle * Math.PI) / 180);
    return {
      transform: `translate(${x}px, -${y}px) scale(${isOpen ? 1 : 0})`,
      transitionDelay: `${isOpen ? index * 0.05 : (totalItems - 1 - index) * 0.05}s`,
    };
  };

  return (
    <div className="fixed bottom-6 right-6 z-50 animate-pulse">
      <div className="relative">
        {/* Menu Items */}
        <ul className="absolute bottom-0 right-0 mb-2 mr-2 list-none p-0">
          {menuItems.map((item, index) => (
            <li
              key={item.id}
              className="absolute bottom-0 right-0 transition-transform duration-300 ease-out"
              style={calculatePosition(index, menuItems.length)}>
              <Link
                to={item.path}
                title={item.label}
                className={`flex size-12 items-center justify-center rounded-full text-white shadow-md hover:opacity-90 ${item.colorClass}`}
                onClick={() => setIsOpen(false)}>
                <FontAwesomeIcon icon={item.icon} size="lg" />
              </Link>
            </li>
          ))}
        </ul>

        {/* Main Toggle Button */}
        <button
          type="button"
          className={`relative z-10 flex size-14 items-center justify-center rounded-full bg-blue-600 text-white shadow-lg transition-transform duration-300 ease-out hover:bg-blue-700 ${
            isOpen ? 'rotate-45' : ''
          }`}
          onClick={toggleMenu}
          aria-haspopup="true"
          aria-expanded={isOpen}
          aria-label="Toggle Actions Menu">
          <FontAwesomeIcon icon={faPlus} size="2x" />
        </button>
      </div>
    </div>
  );
};

export default EasyModePlusNav;
