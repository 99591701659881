import { axiosClientNoCache, csrf } from '@lib/axiosClientCached.ts';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectCurrentCompanySlug } from '@store/reducers/meReducer.ts';
import { addErrorResponseNotification } from '@store/reducers/notificationReducer.ts';
import {
  InterfaceFunctionReplace,
  InterfaceVarReplace,
  replaceUrlState,
  replaceUrlVariable,
  safeDispatchActions,
} from '@store/reducers/reducerHelpers/thunkHelperShared.ts';

import { DispatchActionType } from '@/types/DispatchActionType.ts';

export interface InterfaceThunkDeleteData<T = undefined, U = undefined | InterfaceVarReplace> {
  form?: T;
  urlVarsReplaceMap?: U;
}

const genericTypeThunkDeleteReplace = <T = undefined, U = undefined | InterfaceVarReplace>(
  type: string,
  url: string,
  replaceMap: InterfaceFunctionReplace = {},
  successDispatchArray: DispatchActionType[] = [],
  errorDispatchArray: DispatchActionType[] = [],
) => {
  return createAsyncThunk(
    `${type}/delete/${url}`,
    async (data: InterfaceThunkDeleteData<T, U> | undefined, { rejectWithValue, dispatch, getState }) => {
      let fullUrl = replaceUrlState(url, replaceMap, getState);
      if (data?.urlVarsReplaceMap !== undefined) {
        fullUrl = replaceUrlVariable(fullUrl, data.urlVarsReplaceMap ?? {});
      }
      await csrf();
      let axiosDelCfg = undefined;
      if (data?.form !== undefined) {
        axiosDelCfg = {
          data: data.form,
        };
      }
      const res = await axiosClientNoCache.delete(fullUrl, axiosDelCfg).catch((error) => {
        dispatch(addErrorResponseNotification({ error }));
        safeDispatchActions(dispatch, errorDispatchArray);
        throw rejectWithValue({
          error: error.response.data.errors,
        });
      });
      safeDispatchActions(dispatch, successDispatchArray);
      return res.data.data;
    },
  );
};

const genericTypeThunkDelete = <T = undefined, U = undefined | InterfaceVarReplace>(
  type: string,
  url: string,
  successDispatchArray: DispatchActionType[] = [],
  errorDispatchArray: DispatchActionType[] = [],
) => {
  return genericTypeThunkDeleteReplace<T, U>(type, url, {}, successDispatchArray, errorDispatchArray);
};

const genericTypeThunkDeleteCompany = <T = undefined, U = undefined | InterfaceVarReplace>(
  type: string,
  url: string,
  successDispatchArray: DispatchActionType[] = [],
  errorDispatchArray: DispatchActionType[] = [],
) => {
  return genericTypeThunkDeleteReplace<T, U>(
    type,
    url,
    {
      '{companySlug}': selectCurrentCompanySlug,
    },
    successDispatchArray,
    errorDispatchArray,
  );
};

export const thunkDelete = {
  replUrl: genericTypeThunkDeleteReplace,
  compUrl: genericTypeThunkDeleteCompany,
  del: genericTypeThunkDelete,
};

export default thunkDelete;
