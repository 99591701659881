import { z } from 'zod';

import { locationSchema } from './commonSchemas'; // Assuming locationSchema is in commonSchemas.ts

export const vehicleSchema = z.object({
  uuid: z.string().uuid(),
  createdAt: z.string().datetime({ message: 'Invalid datetime string for createdAt' }),
  updatedAt: z.string().datetime({ message: 'Invalid datetime string for updatedAt' }),
  vehicleStatusTypeSlug: z.string(),
  companySlug: z.string(), // Assuming companySlug is always present and a string
  name: z.string(),
  description: z.string(),
  vehicleModelUuid: z.string().uuid(),
  vehicleMakeTypeSlug: z.string(),
  vehicleSizeTypeSlug: z.string(),
  vehicleColourTypeSlug: z.string(),
  registrationNumber: z.string(),
  vehicleYear: z.string(), // Allow empty string or potentially a year string
  vehicleFeatureTypes: z.array(z.string()),
  registrationNumberChecked: z.boolean(),
  registrationNumberVerified: z.boolean(),
  fleetUuids: z.array(z.string().uuid()),
  location: locationSchema.nullable(), // Use the nullable location schema
});

// Schema for API responses returning a map/object of vehicles keyed by UUID
export const vehicleMapSchema = z.record(z.string().uuid(), vehicleSchema);
