import { MantineThemeOverride } from '@mantine/core';

export interface InterfaceAppConfig {
  app: {
    name: string;
    shortName: string;
    description: string;
    backEndUrl: string;
    defaultRoute: string;
    loggedInDefaultRoute: string;
  };
  map: {
    unit: 'imperial' | 'metric';
    mapBoxAccessToken: string;
    mapDefaultZoom: number;
    mapDefaultCenter: {
      lng: number;
      lat: number;
    };
    defaultBoundAdd: number;
    mapSingleBoundAdd: number;
  };
  mantine: {
    defaultColorScheme: 'light' | 'dark';
    mantineThemeOverride: MantineThemeOverride;
  };
}

const defaultFonts = 'Nunito, sans-serif';

const AppConfig: InterfaceAppConfig = {
  app: {
    name: 'CourierBoost',
    shortName: 'CourierBoost', // Your application's short_name. `string`. Optional. If not set, appName will be used
    description: '',
    backEndUrl: '/api/v1',
    defaultRoute: '/',
    loggedInDefaultRoute: '/CompanyDashboard',
  },
  map: {
    unit: 'imperial',
    mapBoxAccessToken: import.meta.env?.VITE_MAPBOX_TOKEN ?? '',
    mapDefaultZoom: 9,
    mapDefaultCenter: {
      lng: -0.187174,
      lat: 51.52758,
    },
    defaultBoundAdd: 1,
    mapSingleBoundAdd: 0.001,
  },
  mantine: {
    defaultColorScheme: 'light',
    mantineThemeOverride: {
      fontFamily: defaultFonts,
    },
  },
};

export default AppConfig;
