import Dropdown from '@components/Dropdown';
import { checkIfActionFulfilled, routeOnFulfilled } from '@lib/axiosClientCached.ts';
import { useAppDispatch, useAppSelector } from '@store/appStore.ts';
import { getMe, meLogOut, selectMe } from '@store/reducers/meReducer.ts';
import { meCheckIfLoggedIn } from '@store/reducers/reducerHelpers/meCheckIfLoggedIn.ts';
import { selectAppTheme, toggleSidebar, toggleTheme } from '@store/reducers/themeConfigReducer.tsx';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import LogoNavLink from '@/components/Layouts/LogoNavlink';

const EasyModeHeader = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const themeConfig = useAppSelector(selectAppTheme);
  const me = useAppSelector(selectMe);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useAsyncEffect(async () => {
    const resultAction = await dispatch(meCheckIfLoggedIn());
    const result = await checkIfActionFulfilled(resultAction, meCheckIfLoggedIn);
    if (!result) {
      navigate('/login');
    }
  });

  const logout = async () => {
    const resultAction = await dispatch(meLogOut());
    await routeOnFulfilled(navigate, '/', resultAction, meLogOut);
  };
  useEffect(() => {
    const selector = document.querySelector('ul.horizontal-menu a[href="' + window.location.pathname + '"]');
    if (selector) {
      selector.classList.add('active');
      const all: NodeListOf<HTMLElement> = document.querySelectorAll('ul.horizontal-menu .nav-link.active');
      for (let i = 0; i < all.length; i++) {
        all[0]?.classList.remove('active');
      }
      const ul: HTMLElement | null = selector.closest('ul.sub-menu');
      if (ul) {
        const ele: HTMLElement | null | undefined = ul.closest('li.menu')?.querySelector('.nav-link');
        if (ele) {
          setTimeout(() => {
            ele?.classList.add('active');
          });
        }
      }
    }
  }, [location]);

  function createMarkup(messages: string) {
    return { __html: messages };
  }
  const [messages, setMessages] = useState([
    {
      id: 1,
      image:
        '<span class="grid place-content-center w-9 h-9 rounded-full bg-success-light dark:bg-success text-success dark:text-success-light"><svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path></svg></span>',
      title: 'Congratulations!',
      message: 'Hou have won the DELICV006 Bid.',
      time: '1hr',
    },
    {
      id: 2,
      image:
        '<span class="grid place-content-center w-9 h-9 rounded-full bg-info-light dark:bg-info text-info dark:text-info-light"><svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg></span>',
      title: 'Did you know?',
      message: 'You can buy proroty here.',
      time: '2hr',
    },
    {
      id: 3,
      image:
        '<span class="grid place-content-center w-9 h-9 rounded-full bg-danger-light dark:bg-danger text-danger dark:text-danger-light"> <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></span>',
      title: 'Something went wrong!',
      message: 'Send Reposrt',
      time: '2days',
    },
    {
      id: 4,
      image:
        '<span class="grid place-content-center w-9 h-9 rounded-full bg-warning-light dark:bg-warning text-warning dark:text-warning-light"><svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">    <circle cx="12" cy="12" r="10"></circle>    <line x1="12" y1="8" x2="12" y2="12"></line>    <line x1="12" y1="16" x2="12.01" y2="16"></line></svg></span>',
      title: 'Warning',
      message: 'A bid is about to expire.',
      time: '5days',
    },
  ]);

  const removeMessage = (value: number) => {
    setMessages(messages.filter((user) => user.id !== value));
  };

  const [notifications, setNotifications] = useState([
    {
      id: 1,
      profile: 'user-profile.jpeg',
      message: '<strong class="text-sm mr-1">Driver 1</strong>has set<strong>DELIV001</strong> to complete',
      time: '45 min ago',
    },
    {
      id: 2,
      profile: 'profile-34.jpeg',
      message: '<strong class="text-sm mr-1">Company 1</strong>has auctioned a<strong>TYPE1</strong> delivery',
      time: '9h Ago',
    },
  ]);

  const removeNotification = (value: number) => {
    setNotifications(notifications.filter((user) => user.id !== value));
  };

  return (
    <header className={`easy_header z-40 ${themeConfig.isDarkMode && themeConfig.menu === 'horizontal' ? 'dark' : ''}`}>
      <div>
        <div className="relative flex w-full items-center px-5 pb-0 pt-[5px]">
          <div className="horizontal-logo mr-2 flex items-center justify-between lg:hidden">
            <button
              type="button"
              className="collapse-icon ml-2 flex flex-none rounded-full bg-white-light/40 p-1 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:text-[#d0d2d6] dark:hover:bg-dark/60 dark:hover:text-primary lg:hidden"
              onClick={() => {
                dispatch(toggleSidebar());
              }}>
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 7L4 7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                <path opacity="0.5" d="M20 12L4 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M20 17L4 17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
            </button>
            <LogoNavLink />
          </div>
          <div className="header_icon_nav ml-auto flex items-center space-x-1.5 bg-white shadow-sm dark:bg-black dark:text-[#d0d2d6] lg:space-x-2">
            <div className="flex items-center space-x-1.5 lg:space-x-2">
              <div>
                {themeConfig.theme === 'light' ? (
                  <button
                    className={`${
                      themeConfig.theme === 'light' &&
                      'flex items-center rounded-full bg-white-light/40 p-1 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60'
                    }`}
                    onClick={() => {
                      dispatch(toggleTheme('dark'));
                    }}>
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12" cy="12" r="5" stroke="currentColor" strokeWidth="1.5" />
                      <path d="M12 2V4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                      <path d="M12 20V22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                      <path d="M4 12L2 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                      <path d="M22 12L20 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                      <path
                        opacity="0.5"
                        d="M19.7778 4.22266L17.5558 6.25424"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        opacity="0.5"
                        d="M4.22217 4.22266L6.44418 6.25424"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        opacity="0.5"
                        d="M6.44434 17.5557L4.22211 19.7779"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        opacity="0.5"
                        d="M19.7778 19.7773L17.5558 17.5551"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </button>
                ) : (
                  ''
                )}
                {themeConfig.theme === 'dark' && (
                  <button
                    className={`${
                      themeConfig.theme === 'dark' &&
                      'flex items-center rounded-full bg-white-light/40 p-1 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60'
                    }`}
                    onClick={() => {
                      dispatch(toggleTheme('system'));
                    }}>
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M21.0672 11.8568L20.4253 11.469L21.0672 11.8568ZM12.1432 2.93276L11.7553 2.29085V2.29085L12.1432 2.93276ZM21.25 12C21.25 17.1086 17.1086 21.25 12 21.25V22.75C17.9371 22.75 22.75 17.9371 22.75 12H21.25ZM12 21.25C6.89137 21.25 2.75 17.1086 2.75 12H1.25C1.25 17.9371 6.06294 22.75 12 22.75V21.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75V1.25C6.06294 1.25 1.25 6.06294 1.25 12H2.75ZM15.5 14.25C12.3244 14.25 9.75 11.6756 9.75 8.5H8.25C8.25 12.5041 11.4959 15.75 15.5 15.75V14.25ZM20.4253 11.469C19.4172 13.1373 17.5882 14.25 15.5 14.25V15.75C18.1349 15.75 20.4407 14.3439 21.7092 12.2447L20.4253 11.469ZM9.75 8.5C9.75 6.41182 10.8627 4.5828 12.531 3.57467L11.7553 2.29085C9.65609 3.5593 8.25 5.86509 8.25 8.5H9.75ZM12 2.75C11.9115 2.75 11.8077 2.71008 11.7324 2.63168C11.6686 2.56527 11.6538 2.50244 11.6503 2.47703C11.6461 2.44587 11.6482 2.35557 11.7553 2.29085L12.531 3.57467C13.0342 3.27065 13.196 2.71398 13.1368 2.27627C13.0754 1.82126 12.7166 1.25 12 1.25V2.75ZM21.7092 12.2447C21.6444 12.3518 21.5541 12.3539 21.523 12.3497C21.4976 12.3462 21.4347 12.3314 21.3683 12.2676C21.2899 12.1923 21.25 12.0885 21.25 12H22.75C22.75 11.2834 22.1787 10.9246 21.7237 10.8632C21.286 10.804 20.7293 10.9658 20.4253 11.469L21.7092 12.2447Z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                )}
                {themeConfig.theme === 'system' && (
                  <button
                    className={`${
                      themeConfig.theme === 'system' &&
                      'flex items-center rounded-full bg-white-light/40 p-1 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60'
                    }`}
                    onClick={() => {
                      dispatch(toggleTheme('light'));
                    }}>
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3 9C3 6.17157 3 4.75736 3.87868 3.87868C4.75736 3 6.17157 3 9 3H15C17.8284 3 19.2426 3 20.1213 3.87868C21 4.75736 21 6.17157 21 9V14C21 15.8856 21 16.8284 20.4142 17.4142C19.8284 18 18.8856 18 17 18H7C5.11438 18 4.17157 18 3.58579 17.4142C3 16.8284 3 15.8856 3 14V9Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                      />
                      <path opacity="0.5" d="M22 21H2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                      <path opacity="0.5" d="M15 15H9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                  </button>
                )}
              </div>
              <div className="dropdown shrink-0">
                <Dropdown
                  offset={[0, 8]}
                  btnClassName="block p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:text-primary hover:bg-white-light/90 dark:hover:bg-dark/60"
                  button={
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M22 10C22.0185 10.7271 22 11.0542 22 12C22 15.7712 22 17.6569 20.8284 18.8284C19.6569 20 17.7712 20 14 20H10C6.22876 20 4.34315 20 3.17157 18.8284C2 17.6569 2 15.7712 2 12C2 8.22876 2 6.34315 3.17157 5.17157C4.34315 4 6.22876 4 10 4H13"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M6 8L8.1589 9.79908C9.99553 11.3296 10.9139 12.0949 12 12.0949C13.0861 12.0949 14.0045 11.3296 15.8411 9.79908"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <circle cx="19" cy="5" r="3" stroke="currentColor" strokeWidth="1.5" />
                    </svg>
                  }>
                  <ul className="w-[300px] !py-0 text-xs text-dark dark:text-white-dark sm:w-[375px]">
                    <li className="mb-5" onClick={(e) => e.stopPropagation()}>
                      <div className="relative w-full overflow-hidden rounded-t-md px-5 pb-0 pt-5 text-white hover:!bg-transparent">
                        <div
                          className="bg- absolute inset-0 size-full bg-cover bg-center bg-no-repeat"
                          style={{
                            backgroundRepeat: 'no-repeat',
                            width: '100%',
                            height: '100%',
                          }}></div>
                        <h4 className="text-base font-semibold uppercase text-gray-800 dark:text-white-light">
                          Messages
                        </h4>
                      </div>
                    </li>
                    {messages.length > 0 ? (
                      <>
                        <li onClick={(e) => e.stopPropagation()}>
                          {messages.map((message) => {
                            return (
                              <div key={message.id} className="flex items-center px-5 py-3">
                                <div dangerouslySetInnerHTML={createMarkup(message.image)}></div>
                                <span className="px-3 dark:text-gray-500">
                                  <div className="text-sm font-semibold dark:text-white-light/90">{message.title}</div>
                                  <div>{message.message}</div>
                                </span>
                                <span className="ml-auto mr-2 whitespace-pre rounded bg-white-dark/20 px-1 font-semibold text-dark/60 dark:text-white-dark">
                                  {message.time}
                                </span>
                                <button
                                  type="button"
                                  className="text-neutral-300 hover:text-danger"
                                  onClick={() => removeMessage(message.id)}>
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle
                                      opacity="0.5"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                    />
                                    <path
                                      d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                    />
                                  </svg>
                                </button>
                              </div>
                            );
                          })}
                        </li>
                        <li className="mt-5 border-t border-white-light text-center dark:border-white/10">
                          <button
                            type="button"
                            className="group !h-[48px] justify-center !py-4 font-semibold text-primary dark:text-gray-400">
                            <span className="mr-1 group-hover:underline">VIEW ALL ACTIVITIES</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="ml-1 size-4 transition duration-300 group-hover:translate-x-1"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="1.5">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
                            </svg>
                          </button>
                        </li>
                      </>
                    ) : (
                      <li className="mb-5" onClick={(e) => e.stopPropagation()}>
                        <button
                          type="button"
                          className="!grid min-h-[200px] place-content-center text-lg hover:!bg-transparent">
                          <div className="mx-auto mb-4 rounded-full text-white ring-4 ring-primary/30">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="40"
                              height="40"
                              viewBox="0 0 24 24"
                              fill="#a9abb6"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-info rounded-full bg-primary">
                              <line x1="12" y1="16" x2="12" y2="12"></line>
                              <line x1="12" y1="8" x2="12.01" y2="8"></line>
                            </svg>
                          </div>
                          No data available.
                        </button>
                      </li>
                    )}
                  </ul>
                </Dropdown>
              </div>
              <div className="dropdown shrink-0">
                <Dropdown
                  offset={[0, 8]}
                  btnClassName="relative block p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:text-primary hover:bg-white-light/90 dark:hover:bg-dark/60"
                  button={
                    <span>
                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M19.0001 9.7041V9C19.0001 5.13401 15.8661 2 12.0001 2C8.13407 2 5.00006 5.13401 5.00006 9V9.7041C5.00006 10.5491 4.74995 11.3752 4.28123 12.0783L3.13263 13.8012C2.08349 15.3749 2.88442 17.5139 4.70913 18.0116C9.48258 19.3134 14.5175 19.3134 19.291 18.0116C21.1157 17.5139 21.9166 15.3749 20.8675 13.8012L19.7189 12.0783C19.2502 11.3752 19.0001 10.5491 19.0001 9.7041Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M7.5 19C8.15503 20.7478 9.92246 22 12 22C14.0775 22 15.845 20.7478 16.5 19"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <path d="M12 6V10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                      </svg>
                      <span className="absolute right-0 top-0 flex size-3">
                        <span className="absolute left-[-3px] top-[3px] inline-flex size-full animate-ping rounded-full bg-success/50 opacity-75"></span>
                        <span className="relative inline-flex size-[6px] rounded-full bg-success"></span>
                      </span>
                    </span>
                  }>
                  <ul className="w-[300px] divide-y !py-0 text-dark dark:divide-white/10 dark:text-white-dark sm:w-[350px]">
                    <li onClick={(e) => e.stopPropagation()}>
                      <div className="flex items-center justify-between px-4 py-2 font-semibold">
                        <h4 className="text-lg">Notification</h4>
                        {notifications.length ? (
                          <span className="badge bg-primary/80">{notifications.length}New</span>
                        ) : (
                          ''
                        )}
                      </div>
                    </li>
                    {notifications.length > 0 ? (
                      <>
                        {notifications.map((notification) => {
                          return (
                            <li
                              key={notification.id}
                              className="dark:text-white-light/90"
                              onClick={(e) => e.stopPropagation()}>
                              <div className="group flex items-center px-4 py-2">
                                <div className="grid place-content-center rounded">
                                  <div className="relative size-12">
                                    <img
                                      className="size-12 rounded-full object-cover"
                                      alt="profile"
                                      src={`/assets/images/${notification.profile}`}
                                    />
                                    <span className="absolute bottom-0 right-[6px] block size-2 rounded-full bg-success"></span>
                                  </div>
                                </div>
                                <div className="flex flex-auto pl-3">
                                  <div className="pr-3">
                                    <h6
                                      dangerouslySetInnerHTML={{
                                        __html: notification.message,
                                      }}></h6>
                                    <span className="block text-xs font-normal dark:text-gray-500">
                                      {notification.time}
                                    </span>
                                  </div>
                                  <button
                                    type="button"
                                    className="ml-auto text-neutral-300 opacity-0 hover:text-danger group-hover:opacity-100"
                                    onClick={() => removeNotification(notification.id)}>
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <circle
                                        opacity="0.5"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                      />
                                      <path
                                        d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                        <li>
                          <div className="p-4">
                            <button className="btn btn-primary btn-small block w-full">Read All Notifications</button>
                          </div>
                        </li>
                      </>
                    ) : (
                      <li onClick={(e) => e.stopPropagation()}>
                        <button
                          type="button"
                          className="!grid min-h-[200px] place-content-center text-lg hover:!bg-transparent">
                          <div className="mx-auto mb-4 rounded-full ring-4 ring-primary/30">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="40"
                              height="40"
                              viewBox="0 0 24 24"
                              fill="#a9abb6"
                              stroke="#ffffff"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-info rounded-full bg-primary">
                              <line x1="12" y1="16" x2="12" y2="12"></line>
                              <line x1="12" y1="8" x2="12.01" y2="8"></line>
                            </svg>
                          </div>
                          No data available.
                        </button>
                      </li>
                    )}
                  </ul>
                </Dropdown>
              </div>
              <div className="dropdown flex shrink-0">
                <Dropdown
                  offset={[0, 8]}
                  btnClassName="relative group block"
                  button={
                    <div className="flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60">
                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="6" r="4" stroke="currentColor" strokeWidth="1.5" />
                        <path
                          opacity="0.5"
                          d="M20 17.5C20 19.9853 20 22 12 22C4 22 4 19.9853 4 17.5C4 15.0147 7.58172 13 12 13C16.4183 13 20 15.0147 20 17.5Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </div>
                  }>
                  <ul className="w-[230px] !py-0 font-semibold text-dark dark:text-white-dark dark:text-white-light/90">
                    <li>
                      <div className="flex items-center p-4">
                        <img className="size-10 rounded-md object-cover" alt="userProfile" src={me?.imgThumbUrl} />
                        <div className="truncate pl-4">
                          <h4 className="text-base">
                            {me?.firstname} {me?.lastname}
                            <span className="ml-2 rounded bg-success-light px-1 text-xs text-success">Pro</span>
                          </h4>
                          <button
                            type="button"
                            className="text-black/60 hover:text-primary dark:text-dark-light/60 dark:hover:text-white">
                            {me?.email}
                          </button>
                        </div>
                      </div>
                    </li>
                    <li>
                      <Link to="/AccountPages/UserProfile" className="dark:hover:text-white">
                        <svg
                          className="mr-2 shrink-0"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <circle cx="12" cy="6" r="4" stroke="currentColor" strokeWidth="1.5" />
                          <path
                            opacity="0.5"
                            d="M20 17.5C20 19.9853 20 22 12 22C4 22 4 19.9853 4 17.5C4 15.0147 7.58172 13 12 13C16.4183 13 20 15.0147 20 17.5Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                          />
                        </svg>
                        Profile
                      </Link>
                    </li>
                    <li>
                      <Link to="/apps/mailbox" className="dark:hover:text-white">
                        <svg
                          className="mr-2 shrink-0"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            opacity="0.5"
                            d="M2 12C2 8.22876 2 6.34315 3.17157 5.17157C4.34315 4 6.22876 4 10 4H14C17.7712 4 19.6569 4 20.8284 5.17157C22 6.34315 22 8.22876 22 12C22 15.7712 22 17.6569 20.8284 18.8284C19.6569 20 17.7712 20 14 20H10C6.22876 20 4.34315 20 3.17157 18.8284C2 17.6569 2 15.7712 2 12Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                          />
                          <path
                            d="M6 8L8.1589 9.79908C9.99553 11.3296 10.9139 12.0949 12 12.0949C13.0861 12.0949 14.0045 11.3296 15.8411 9.79908L18 8"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                        </svg>
                        Inbox
                      </Link>
                    </li>

                    <li className="border-t border-white-light dark:border-white-light/10">
                      <Link className="!py-3 text-danger" to={'#'} onClick={logout}>
                        <svg
                          className="mr-2 shrink-0 rotate-90"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            opacity="0.5"
                            d="M17 9.00195C19.175 9.01406 20.3529 9.11051 21.1213 9.8789C22 10.7576 22 12.1718 22 15.0002V16.0002C22 18.8286 22 20.2429 21.1213 21.1215C20.2426 22.0002 18.8284 22.0002 16 22.0002H8C5.17157 22.0002 3.75736 22.0002 2.87868 21.1215C2 20.2429 2 18.8286 2 16.0002L2 15.0002C2 12.1718 2 10.7576 2.87868 9.87889C3.64706 9.11051 4.82497 9.01406 7 9.00195"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                          <path
                            d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Sign Out
                      </Link>
                    </li>
                  </ul>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default EasyModeHeader;
