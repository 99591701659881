import axiosClientCached from '@lib/axiosClientCached.ts';
import { selectCurrentCompanySlug } from '@store/reducers/meReducer.ts';
import { addErrorResponseNotification } from '@store/reducers/notificationReducer.ts';
import createDebouncedAsyncThunk from '@store/reducers/reducerHelpers/createDebouncedAsyncThunk.ts';
import {
  defaultTypeDebounceTimeMs,
  InterfaceFunctionReplace,
  InterfaceVarReplace,
  replaceUrlState,
  replaceUrlVariable,
  safeDispatchActions,
} from '@store/reducers/reducerHelpers/thunkHelperShared.ts';

import { DispatchActionType } from '@/types/DispatchActionType.ts';

export interface InterfaceThunkGetData<U = undefined | InterfaceVarReplace> {
  urlVarsReplaceMap?: U;
}

const genericTypeThunkGetReplace = <U = undefined | InterfaceVarReplace>(
  type: string,
  url: string,
  replaceMap: InterfaceFunctionReplace = {},
  successDispatchArray: DispatchActionType[] = [],
  errorDispatchArray: DispatchActionType[] = [],
  debounceTimeMs?: number | undefined,
) => {
  debounceTimeMs = debounceTimeMs ?? defaultTypeDebounceTimeMs;
  return createDebouncedAsyncThunk(
    `${type}/get/${url}`,
    async (data: InterfaceThunkGetData<U> | undefined, { rejectWithValue, dispatch, getState }) => {
      let fullUrl = replaceUrlState(url, replaceMap, getState);
      if (data?.urlVarsReplaceMap !== undefined) {
        fullUrl = replaceUrlVariable(fullUrl, data?.urlVarsReplaceMap ?? {});
      }
      const res = await axiosClientCached.get(fullUrl).catch((error) => {
        dispatch(addErrorResponseNotification({ error }));
        safeDispatchActions(dispatch, errorDispatchArray);
        throw rejectWithValue({
          error: error.response.data.errors,
        });
      });
      safeDispatchActions(dispatch, successDispatchArray);
      return res.data.data;
    },
    debounceTimeMs,
  );
};

const genericTypeThunkGet = <U = undefined | InterfaceVarReplace>(
  type: string,
  url: string,
  successDispatchArray: DispatchActionType[] = [],
  errorDispatchArray: DispatchActionType[] = [],
  debounceTimeMs?: number | undefined,
) => {
  return genericTypeThunkGetReplace<U>(type, url, {}, successDispatchArray, errorDispatchArray, debounceTimeMs);
};

const genericTypeThunkGetCompany = <U = undefined | InterfaceVarReplace>(
  type: string,
  url: string,
  successDispatchArray: DispatchActionType[] = [],
  errorDispatchArray: DispatchActionType[] = [],
  debounceTimeMs?: number | undefined,
) => {
  return genericTypeThunkGetReplace<U>(
    type,
    url,
    {
      '{companySlug}': selectCurrentCompanySlug,
    },
    successDispatchArray,
    errorDispatchArray,
    debounceTimeMs,
  );
};

export const thunkGet = {
  replUrl: genericTypeThunkGetReplace,
  compUrl: genericTypeThunkGetCompany,
  get: genericTypeThunkGet,
};

export default thunkGet;

//TODO: See iof we can add a default for current delivery uuid
