import { InterfaceGenericMap } from '@interfaces/InterfaceGenericMap.ts';
import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '@store/appStore.ts';
import { meLogOut } from '@store/reducers/meReducer.ts';
import createDebouncedAsyncThunk from '@store/reducers/reducerHelpers/createDebouncedAsyncThunk.ts';
import { sliceStateCheckAndUpdate } from '@store/reducers/reducerHelpers/sliceStateCheckAndUpdate.ts';
import { thunkGet } from '@store/reducers/reducerHelpers/thunkGet.ts';
import { defaultTypeDebounceTimeMs } from '@store/reducers/reducerHelpers/thunkHelperShared.ts';
import memoize from 'lodash-es/memoize';

import thunkPost from './reducerHelpers/thunkPost';

export interface InterfaceParcelInfo {
  uuid: string;
  originatorParcelId: string;
  heightCm: number;
  widthCm: number;
  lengthCm: number;
  volumeCm3: number;
  weightKg: number;
  priceMinor: number;
  parcelItemsJson: Record<string, unknown>;
  parcelHandlingInstructionsJson: string[];
  notesJson: string[];
  dataJson: Record<string, unknown>;
  location?: {
    latitude: number;
    longitude: number;
  };
  locationJson?: Record<string, unknown>;
  createdAt: string;
  updatedAt: string;
}

export interface ParcelReducerState {
  parcelUuids: string[];
  parcelInfo: InterfaceGenericMap<InterfaceParcelInfo>;
}

const initialState: ParcelReducerState = {
  parcelUuids: [],
  parcelInfo: {},
};

export const sliceName = 'parcel';

export const parcelSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(meLogOut.fulfilled, () => initialState)
      .addCase(getCompanyParcelUuids.fulfilled, sliceStateCheckAndUpdate.sliceItem('parcelUuids'))
      .addCase(getCompanyParcelInfo.fulfilled, sliceStateCheckAndUpdate.sliceItem('parcelInfo'));
  },
});

export const getCompanyParcelUuids = thunkGet.compUrl(sliceName, '/c/{companySlug}/admin/parcel/uuid');
export const getCompanyParcelInfo = thunkGet.compUrl(sliceName, '/c/{companySlug}/admin/parcel/info');

export interface AddParcelFormValues {
  originatorParcelId: string;
  heightCm: number;
  widthCm: number;
  depthCm: number;
  weightKg: number;
  priceMinor: number;
  parcelItems?: Record<string, unknown>;
  handlingInstructions?: string[];
  notes?: string[];
}

export const addCompanyParcel = thunkPost.compUrl<AddParcelFormValues>(sliceName, '/c/{companySlug}/admin/parcel', [
  getCompanyParcelUuids(),
  getCompanyParcelInfo(),
]);

export const selectParcelState = (state: AppState) => state.parcel;
export const selectParcelUuids = memoize((state: AppState) => selectParcelState(state).parcelUuids);
export const selectParcelInfo = memoize((state: AppState) => selectParcelState(state).parcelInfo);

export default parcelSlice.reducer;

export const loadInitialParcelData = createDebouncedAsyncThunk(
  `${sliceName}/initial`,
  async (_, { dispatch }) => {
    dispatch(getCompanyParcelInfo());
    dispatch(getCompanyParcelUuids());
  },
  defaultTypeDebounceTimeMs,
);
