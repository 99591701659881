import { z } from 'zod';

export const locationSchema = z
  .object({
    lat: z.number(),
    lng: z.number(),
  })
  .nullable(); // Allow null based on InterfaceDelivery

export const noteSchema = z.object({
  message: z.string(),
  noteObject: z.unknown(), // Keep as unknown unless you know the structure
});

export const scheduleWindowSchema = z.object({
  startAt: z.string().datetime({ message: 'Invalid datetime string for startAt' }),
  endAt: z.string().datetime({ message: 'Invalid datetime string for endAt' }),
  deliveryScheduleWindowTypeSlug: z.string(),
});

export const moneySchema = z.object({
  priceMinor: z.number(),
  priceString: z.string(),
  currencyCode: z.string().length(3),
});

export const interfaceVehicleMapSchema = z.object({
  vehicleUuids: z
    .record(
      z.string().uuid(),
      z.object({
        driverUserUuids: z.array(z.string().uuid()),
      }),
    )
    .or(z.record(z.string(), z.never()))
    .or(z.array(z.string()))
    .optional(),
});

export const deliveryNextStatusOptionsSchema = z.object({
  user: z.array(z.string()),
  system: z.array(z.string()),
});
