import LogoSquare from '@assets/images/logo-only-square.webp';
import { faBox } from '@fortawesome/pro-duotone-svg-icons/faBox';
import { faBuilding } from '@fortawesome/pro-duotone-svg-icons/faBuilding';
import { faCalendarDays } from '@fortawesome/pro-duotone-svg-icons/faCalendarDays';
import { faIdCard } from '@fortawesome/pro-duotone-svg-icons/faIdCard';
import { faList } from '@fortawesome/pro-duotone-svg-icons/faList';
import { faPlusCircle } from '@fortawesome/pro-duotone-svg-icons/faPlusCircle';
import { faTruck } from '@fortawesome/pro-duotone-svg-icons/faTruck';
import { faUser } from '@fortawesome/pro-duotone-svg-icons/faUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from '@store/appStore.ts';
import { selectAppThemeIsSemiDark } from '@store/reducers/themeConfigReducer.tsx';
import { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { NavLink } from 'react-router-dom';

import LogoNavLink from '@/components/Layouts/LogoNavlink';

const EasyModeSidebar = () => {
  const [currentMenu, setCurrentMenu] = useState<string>('');
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const isSemiDark = useAppSelector(selectAppThemeIsSemiDark);

  useEffect(() => {
    const selector = document.querySelector('.sidebar ul a[href="' + window.location.pathname + '"]');
    if (selector) {
      selector.classList.add('active');
      const ul: HTMLUListElement | null = selector.closest('ul.sub-menu');
      if (ul) {
        const ele: HTMLElement | null = ul.closest('li.menu')?.querySelector('.nav-link') || null;
        if (ele) {
          setTimeout(() => {
            ele?.click();
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    const contentSelectors = ['.main-content', '.easymode-content', '.easymode-content-expanded'];
    let mainContent: HTMLElement | null = null;

    for (const selector of contentSelectors) {
      const element = document.querySelector(selector) as HTMLElement | null;
      if (element) {
        mainContent = element;
        break;
      }
    }

    if (!mainContent) {
      mainContent = document.querySelector('.main-container') as HTMLElement | null;
    }

    if (mainContent) {
      const isMobile = window.innerWidth <= 767;

      if (isExpanded) {
        mainContent.style.marginLeft = '260px';
        mainContent.style.transition = 'margin-left 300ms ease';
      } else {
        mainContent.style.marginLeft = isMobile ? '0px' : '80px';
        mainContent.style.transition = 'margin-left 300ms ease';
      }
    }
  }, [isExpanded]);

  return (
    <div className={isSemiDark ? 'dark' : ''}>
      <nav
        className={`sidebar fixed inset-y-0 z-50 h-full min-h-screen shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] transition-all duration-300 ${
          isExpanded ? 'w-[260px]' : 'w-[70px]'
        } ${isSemiDark ? 'text-white-dark' : ''}`}
        onMouseEnter={() => setIsExpanded(true)}
        onMouseLeave={() => {
          setIsExpanded(false);
          setCurrentMenu('');
        }}>
        <div className="h-full bg-white dark:bg-black">
          <div className="flex items-center justify-between px-4 py-3">
            {isExpanded ? (
              <LogoNavLink />
            ) : (
              <div className="mx-auto flex size-10 items-center justify-center">
                <img src={LogoSquare} alt="Logo" className="size-10 object-contain" />
              </div>
            )}

            {isExpanded && (
              <button
                type="button"
                className="collapse-icon flex size-8 items-center rounded-full transition duration-300 hover:bg-gray-500/10 dark:text-white-light dark:hover:bg-dark-light/10">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="m-auto size-5">
                  <path
                    d="M13 19L7 12L13 5"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    opacity="0.5"
                    d="M16.9998 19L10.9998 12L16.9998 5"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            )}
          </div>

          <PerfectScrollbar className="relative flex h-[calc(100vh-80px)] flex-col">
            <ul className="relative space-y-0.5 p-4 py-0 font-semibold">
              <li className="menu nav-item">
                <NavLink to="/CompanyDashboard" className={`nav-link group ${!isExpanded ? 'justify-center' : ''}`}>
                  <div className={`flex items-center ${!isExpanded ? 'justify-center' : ''}`}>
                    <FontAwesomeIcon icon={faBuilding} className={!isExpanded ? 'text-xl' : ''} />
                    {isExpanded && (
                      <span className="pl-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                        Dashboard
                      </span>
                    )}
                  </div>
                </NavLink>
              </li>

              <li className="menu nav-item">
                <NavLink to="/DeliveryCalendar" className={`nav-link group ${!isExpanded ? 'justify-center' : ''}`}>
                  <div className={`flex items-center ${!isExpanded ? 'justify-center' : ''}`}>
                    <FontAwesomeIcon icon={faCalendarDays} className={!isExpanded ? 'text-xl' : ''} />
                    {isExpanded && (
                      <span className="pl-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                        Delivery Calendar
                      </span>
                    )}
                  </div>
                </NavLink>
              </li>

              <li className="menu nav-item">
                <NavLink to="/DeliveryAddEasy" className={`nav-link group ${!isExpanded ? 'justify-center' : ''}`}>
                  <div className={`flex items-center ${!isExpanded ? 'justify-center' : ''}`}>
                    <FontAwesomeIcon icon={faPlusCircle} className={!isExpanded ? 'text-xl' : ''} />
                    {isExpanded && (
                      <span className="pl-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                        Add Delivery
                      </span>
                    )}
                  </div>
                </NavLink>
              </li>

              {/* Courier Dashboard Item Removed */}
              {/* <li className="menu nav-item">
                <NavLink to="/CourierDashboard" className={`nav-link group ${!isExpanded ? 'justify-center' : ''}`}>
                  <div className={`flex items-center ${!isExpanded ? 'justify-center' : ''}`}>
                    <FontAwesomeIcon icon={faVanShuttle} className={!isExpanded ? 'text-xl' : ''} />
                    {isExpanded && (
                      <span className="pl-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                        Courier Dashboard
                      </span>
                    )}
                  </div>
                </NavLink>
              </li> */}

              {isExpanded && (
                <>
                  <li
                    className="menu nav-item"
                    onMouseEnter={() => setCurrentMenu('users')}
                    onMouseLeave={() => setCurrentMenu('')}>
                    <button
                      type="button"
                      className={`${currentMenu === 'users' ? 'active' : ''} nav-link group w-full`}
                      style={{ pointerEvents: 'none' }}>
                      <div className="flex items-center">
                        <FontAwesomeIcon icon={faTruck} />
                        <span className="pl-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                          Fleet Management
                        </span>
                      </div>

                      <div className={currentMenu === 'users' ? 'rotate-90' : ''}>
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9 5L15 12L9 19"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </button>

                    <AnimateHeight duration={300} height={currentMenu === 'users' ? 'auto' : 0}>
                      <ul className="sub-menu light-blue-100">
                        <li>
                          <NavLink to="/VehicleManage">Manage Vehicles</NavLink>
                        </li>
                        <li>
                          <NavLink to="/VehicleAdd">Add Vehicles</NavLink>
                        </li>
                        <li>
                          <NavLink to="/VehicleTypeAdd">Add Vehicle Type</NavLink>
                        </li>
                      </ul>
                    </AnimateHeight>
                  </li>
                </>
              )}
              {!isExpanded && (
                <li className="menu nav-item">
                  <NavLink to="/FleetManagement" className="nav-link group justify-center">
                    <div className="flex items-center justify-center">
                      <FontAwesomeIcon icon={faTruck} className="text-xl" />
                    </div>
                  </NavLink>
                </li>
              )}

              {isExpanded && (
                <>
                  <li
                    className="menu nav-item"
                    onMouseEnter={() => setCurrentMenu('parcels')}
                    onMouseLeave={() => setCurrentMenu('')}>
                    <button
                      type="button"
                      className={`${currentMenu === 'parcels' ? 'active' : ''} nav-link group w-full`}
                      style={{ pointerEvents: 'none' }}>
                      <div className="flex items-center">
                        <FontAwesomeIcon icon={faBox} />
                        <span className="pl-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                          Parcel Management
                        </span>
                      </div>

                      <div className={currentMenu === 'parcels' ? 'rotate-90' : ''}>
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9 5L15 12L9 19"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </button>

                    <AnimateHeight duration={300} height={currentMenu === 'parcels' ? 'auto' : 0}>
                      <ul className="sub-menu light-blue-100">
                        <li>
                          <NavLink to="/ParcelAdd">Add New Parcel</NavLink>
                        </li>
                        <li>
                          <NavLink to="/ParcelTrack">Track Parcel</NavLink>
                        </li>
                      </ul>
                    </AnimateHeight>
                  </li>
                </>
              )}
              {!isExpanded && (
                <li className="menu nav-item">
                  <NavLink to="/ParcelAdd" className="nav-link group justify-center">
                    <div className="flex items-center justify-center">
                      <FontAwesomeIcon icon={faBox} className="text-xl" />
                    </div>
                  </NavLink>
                </li>
              )}

              {/* Company Users in Account section - MOVED UP */}
              {isExpanded && (
                <>
                  <li
                    className="menu nav-item"
                    onMouseEnter={() => setCurrentMenu('company')}
                    onMouseLeave={() => setCurrentMenu('')}>
                    <button
                      type="button"
                      className={`${currentMenu === 'company' ? 'active' : ''} nav-link group w-full`}
                      style={{ pointerEvents: 'none' }}>
                      <div className="flex items-center">
                        <FontAwesomeIcon icon={faUser} />
                        <span className="pl-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                          User Management
                        </span>
                      </div>

                      <div className={currentMenu === 'company' ? 'rotate-90' : ''}>
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9 5L15 12L9 19"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </button>

                    <AnimateHeight duration={300} height={currentMenu === 'company' ? 'auto' : 0}>
                      <ul className="sub-menu light-blue-100">
                        <li>
                          <NavLink to="/DriversManage">Manage Drivers</NavLink>
                        </li>
                        <li>
                          <NavLink to="/DriverAddCompany">Add Driver</NavLink>
                        </li>
                        <li>
                          <NavLink to="/AdminAddCompany">Add Admin</NavLink>
                        </li>
                      </ul>
                    </AnimateHeight>
                  </li>
                </>
              )}
              {!isExpanded && (
                <li className="menu nav-item">
                  <NavLink to="/DriversManage" className="nav-link group justify-center">
                    <div className="flex items-center justify-center">
                      <FontAwesomeIcon icon={faUser} className="text-xl" />
                    </div>
                  </NavLink>
                </li>
              )}

              <li className="menu nav-item">
                <NavLink to="/DriverDashboard" className={`nav-link group ${!isExpanded ? 'justify-center' : ''}`}>
                  <div className={`flex items-center ${!isExpanded ? 'justify-center' : ''}`}>
                    <FontAwesomeIcon icon={faIdCard} className={!isExpanded ? 'text-xl' : ''} />
                    {isExpanded && (
                      <span className="pl-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                        Driver Dashboard
                      </span>
                    )}
                  </div>
                </NavLink>
              </li>
            </ul>

            <div className="mt-auto p-4">
              {isExpanded && (
                <ul className="relative space-y-0.5 font-semibold">
                  <li className="menu nav-item">
                    <NavLink to="https://courierboost.com" target="_blank" className="nav-link group">
                      <div className="flex items-center">
                        <FontAwesomeIcon icon={faList} />
                        <span className="pl-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                          Support Desk
                        </span>
                      </div>
                    </NavLink>
                  </li>
                </ul>
              )}
              {!isExpanded && (
                <ul className="relative space-y-0.5 font-semibold">
                  <li className="menu nav-item">
                    <NavLink to="https://courierboost.com" target="_blank" className="nav-link group justify-center">
                      <div className="flex items-center justify-center">
                        <FontAwesomeIcon icon={faList} className="text-xl" />
                      </div>
                    </NavLink>
                  </li>
                </ul>
              )}
            </div>
          </PerfectScrollbar>
        </div>
      </nav>
    </div>
  );
};

export default EasyModeSidebar;
